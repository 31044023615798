import React from 'react'
import { graphql } from 'gatsby'

import {
  Facebook,
  Instagram,
  YoutubeSquare,
  Linkedin,
} from 'emotion-icons/fa-brands'

import SEO from '../components/seo'
import RichText from '../components/richText'

import { Container } from '~/utils/styles'
//import { AboutCols, Team, Social } from './styles'

import styled from '@emotion/styled'

import { breakpoints } from '~/utils/styles'

const AboutCols = styled.div`
  h3 {
    font-size: 1.7rem;
    text-align: center;
    font-weight: 300;
    margin: 3em 0;
  }
  @media (min-width: ${breakpoints.l}px) {
    display: flex;
    margin: 0 -4rem;
  }
  > div {
    @media (min-width: ${breakpoints.l}px) {
      padding: 0 4rem;
    }
    &:nth-of-type(1) {
      @media (min-width: ${breakpoints.l}px) {
        width: 66.66%;
      }
    }
    &:nth-of-type(2) {
      @media (min-width: ${breakpoints.l}px) {
        width: 33.33%;
      }
    }
  }
`

const Team = styled.ul`
  list-style: none;
  padding: 0;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem;
  li {
    margin-bottom: 5rem;
    width: 50%;
    padding: 0 1rem;
    @media (min-width: ${breakpoints.l}px) {
      width: 33.33%;
    }
    @media (min-width: ${breakpoints.xl}px) {
      width: 20%;
      padding: 0 1rem;
    }
  }
  p,
  h4 {
    font-size: 1.4rem;
    margin: 0;
    font-weight: normal;
  }
  p {
    min-height: 1.5em;
  }
  img {
    width: 100%;
    border-radius: 2rem;
    display: block;
    margin-top: 2rem;
  }
`

const Social = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  li {
    width: 3rem;
    margin-right: 1rem;
  }
`

const About = ({ data }) => {
  const {
    about_us: aboutUs,
    address,
    email,
    team,
    facebook,
    instagram,
    youtube,
    linkedin,
  } = data.allPrismicAbout.edges[0].node.data

  return (
    <>
      <SEO title="About us" />
      <AboutCols>
        <div>
          <h2>About us</h2>
          <RichText render={aboutUs.raw} />
          <div>
            <h3>Our team has a way for you – DIGITAL FASHION</h3>
            <Team>
              {team.map((person, i) => {
                return (
                  <li key={i}>
                    <h4>{person.name}</h4>
                    <p>{person.position}</p>
                    <img src={person.photo.url} />
                  </li>
                )
              })}
            </Team>
          </div>
        </div>
        <div>
          <h2>Contact Address</h2>
          <RichText render={address.raw} />
          <br />
          <h2>Contact us</h2>
          <p>
            <a href={`mailto:${email}`}>{email}</a>
          </p>
          <br />
          <h2>Follow us</h2>
          <Social>
            {facebook && (
              <li>
                <a href={facebook} target="_blank">
                  <Facebook />
                </a>
              </li>
            )}
            {instagram && (
              <li>
                <a href={instagram} target="_blank">
                  <Instagram />
                </a>
              </li>
            )}
            {youtube && (
              <li>
                <a href={youtube} target="_blank">
                  <YoutubeSquare />
                </a>
              </li>
            )}
            {linkedin && (
              <li>
                <a href={linkedin} target="_blank">
                  <Linkedin />
                </a>
              </li>
            )}
          </Social>
        </div>
      </AboutCols>
    </>
  )
}

export const query = graphql`
  {
    allPrismicAbout {
      edges {
        node {
          data {
            about_us {
              raw
            }
            address {
              raw
            }
            email
            team {
              name
              position
              photo {
                url
              }
            }
            facebook
            instagram
            linkedin
            youtube
          }
        }
      }
    }
  }
`

export default About
